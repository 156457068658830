import { EmploymentHistory } from '../pages/EmploymentHistory';
import { MainPage } from '../pages/index';
import { EducationHistory } from '../pages/EducationHistory';
import { ProfilePage } from "../pages/Profile";
import { about, socialMediaLinks } from "./personalInfo";
import { CommunityInv } from '../pages/Community';
import { LeadershipPage } from '../pages/LeadershipWork';

export const repoName = '';

export const navLinks = [
    {
        name: 'Home',
        link: '/',
        objectClass: 'Home',
        pageName: MainPage,
        hash: `${repoName}/#`,
    },{
        name: 'About',
        link: '/about',
        objectClass: '',
        pageName: ProfilePage,
        hash: `${repoName}/#`,
    },{
        name: 'Employment',
        link: '/employment',
        objectClass: 'EmploymentHistory',
        pageName: EmploymentHistory,
        hash: `${repoName}/#`,
    },{
        name: 'Education',
        link: '/education',
        objectClass: 'EducationHistory',
        pageName: EducationHistory,
        hash: `${repoName}/#`,
    },{
        name: 'Community',
        link: '/community',
        objectClass: '',
        pageName: CommunityInv,
        hash: `${repoName}/#`,
    },{
        name: 'Academic Leadership',
        link: '/leadership',
        objectClass: '',
        pageName: LeadershipPage,
        hash: `${repoName}/#`,
    },/*{
        name: 'Portfolio',
        link: '/',
        objectClass: '',
        pageName: '#',
        hash: `${repoName}/#`,
    },*//*{
        name: 'Contact',
        link: '/',
        objectClass: '',
        pageName: '#',
        hash: `${repoName}/#`,
    },*/

    
];

export const socialLinks = [
        /*{
            site: 'Twitter',
            iconClass: 'fa-twitter',
            objectClass: 'fa',
            link: socialMediaLinks.Twitter,
            logoColor: '#55ACEE',
        },*//*{
            site: 'Facebook',
            iconClass: 'fa-facebook',
            objectClass: 'fa',
            link: socialMediaLinks.Facebook,
            logoColor: '#3B5998',
        },*/{
            site: 'LinkedIn',
            iconClass: 'fa-linkedin',
            objectClass: 'fa',
            link: socialMediaLinks.LinkedIn,
            logoColor: '#ff5700',
        },/*{
            site: 'Pinterest',
            iconClass: 'fa-pinterest',
            objectClass: 'fa',
            link: socialMediaLinks.Pinterest,
            logoColor: '#cb2027',
        },*//*{
            site: 'Instagram',
            iconClass: 'fa-instagram',
            objectClass: 'fa',
            link: socialMediaLinks.Instagram,
            logoColor: '#125688',
        },*//*{
            site: 'YouTube',
            iconClass: 'fa-youtube',
            objectClass: 'fa',
            link: socialMediaLinks.YouTube,
            logoColor: '#bb0000',
        },*//*{
            site: 'GitHub',
            iconClass: 'fa-github',
            objectClass: 'fa',
            link: `https://github.com/MopishHobgoblin`,
            logoColor: '#ffffff',
        },*/
        {
            site: 'Email',
            iconClass: 'fa-envelope',
            objectClass: 'fa',
            link: `mailto:${about.email}`,
            logoColor: '#00b489',
        },
];