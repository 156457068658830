export const jumboTron = {
    name: 'Education',
    subHead: '',
};

export const education = [
    {
        institution: 'The University of Leeds',
        qualification: 'Master of Arts | Arts Management and Heritage Studies',
        startYear: 2019,
        endYear: 2020,
        highlights: ['Research Interests: Oral History, audience participation, accessibility, interactive theatrical and museum experience.'],
        attainment: [],
    },{
        institution: 'The University of North Carolina at Greensboro',
        qualification: 'Bachelor of Arts | Major: Theatre | Second Major: English',
        startYear: 2005,
        endYear: 2009,
        highlights: ['Minor: History', 'Related course work: Publicity, Theatre Management, Marketing, American Sign Language'],
        attainment: [],
    },/*{
        institution: '',
        qualification: 'Bachelor of Arts',
        startYear: 2,
        endYear: 2012,
        highlights: [],
        attainment: [],
    },*/
];

export const postgradLeader = [
        /*{
            details: '',
            years: '',
            extras: '',
    },*/{
            details: 'Postgraduate School Leader for the School of Fine Arts, History of Art and Cultural Studies. ',
            years: '2019 – 2020',
            extras: '',
    },
];

export const undergradLeader = [
    /*{
        details: '',
        years: '',
        extras: [],
    },*/{
        details: 'Chosen as a campus leader to participate as part of the inauguration of Chancellor Linda P. Brady. ',
        years: '2009',
        extras: [],
    },{
        details: 'Campus Greek Association',
        years: '2007 - 2009',
        extras: ['President (2008 - 2009)', 'Secretary (2007 - 2008)', 'Sorority Representative (2006 - 2007)'],
    },{
        details: 'Member of Alpha Delta Pi Social Sorority',
        years: '2005 - Present',
        extras: ['Social Chairman',],
    },{
        details: 'Panhellenic Sorority Council',
        years: '2007 - 2008',
        extras: ['Vice President of Humanities',],
    },{
        details: 'Young Democrats Club',
        years: '2006 - 2009',
        extras: [],
    },{
        details: 'UNCG Lions Club, founding member',
        years: '2009',
        extras: [],
    },
];

export const undergradPlanning = [
    /*{
        details: '',
        years: '',
    },*/{
        details: 'Oversaw the planning and coordination of annual Greek Week festivities for over 600 members of social Greek community which included five simultaneous community service projects.',
        years: '2009',
    },{
        details: 'Served on the planning board and assisted in the execution of annual Greek Week festivities.',
        years: '2007 - 2008',
    },{
        details: 'Planned and coordinated a campus wide “Belle of the Ball” dress sale sponsored by College Panhellenic Council',
        years: '2008',
    },{
        details: 'Planned and coordinated Theatre Department end of the year “Bash”.',
        years: '2006, 2007',
    },{
        details: 'Participated in a promotional event for the touring company of “Thoroughly Modern Millie”.',
        years: '2006',
    },{
        details: 'Coordinated Alpha Delta Pi “Black and White Affair” Date Party.',
        years: '2006',
    },
];