import React from 'react'
import {education, undergradLeader, undergradPlanning, postgradLeader} from './Education';
import {profile, profilePicture, careerHighlights, competencies} from './Profile';
import {employment, communityInvolvement, volunteerProjects, otherTheatreExperience} from './EmploymentHistory';
import { guideColors } from "./StyleObjects";
import { jumboInfo, fullName } from './personalInfo';
import { navLinks } from './pageLinks';

export const jumbo = (objectName = jumboInfo) => {
  return (
    <div
      className="jumbotron jumbotron-fluid"
      style={{
      backgroundColor: `${guideColors.header}`
    }}>
      <div className="container">
        <h1 className="display-4">{objectName.name}</h1>
        <p className="lead">{objectName.subHead}</p>
      </div>
    </div>
  )
};

export const insertProfilePicture = (objectName = profilePicture) => {
  return (
    <img
      style={{
      width: '25em',
      float: 'right',
      marginTop: '-1em',
      paddingLeft: "2em",
      paddingBottom: "3em",
      zIndex: 10,
    }}
      alt={objectName.alt}src={objectName.url} ></img>
  );
};

export const educationProfile = (objectName = education) => {
  return (
    <div className="Education">
      {objectName.map(item => {
        return (
          <div className={item.institution}>
            <h2>{item.institution}</h2>
            <h3>{item.qualification}</h3>
            <h4>{item.startYear}
              - {item.endYear}</h4>
            <ul>
              {item
                .highlights
                .map((value, index) => {
                  return <li key={index}>{value}</li>
                })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export const blurb = (objectName = profile) => {
  return (
    <div className="Blurb">
      {objectName
        .about
        .map((scentence, i) => {
          return <p key={i}>{scentence}</p>
        })}
    </div>
  )
}

export const careerParagraph = (objectName = careerHighlights) => {
  return (
    <div className="CareerHighlights">
      <h2 style={{
        paddingBottom: '0.4em'
      }}>{objectName.name}</h2>
      <ul>
        {objectName
          .about
          .map((point, i) => {
            return <li key={i}>{point}</li>
          })}</ul>
    </div>
  )
}

export const workCompetencies = (objectName = competencies) => {
  return (
    <div className="Competencies">
      <h2
        style={{
        paddingTop: '0.8em',
        paddingBottom: '0.4em'
      }}>{objectName.name}</h2>
      <ul>
        {objectName
          .about
          .map((point, i) => {
            return <li key={i}>{point}</li>
          })}
      </ul>
    </div>
  )
}

export const employmentFunction = (objectName = employment) => {
  return (
    <div>
      {objectName.map(job => {
        return (
          <div className="Employment">
            <div className={job.company}>
               <h2>{job.company}</h2>
               {job.jobTitle.map((value, index) => {
                  return <h3 key={index}>{value}</h3>
                })}
              <h4>{`${job.startDate} - ${job.endDate}`}</h4>
                {job
                  .duties
                  .map((value, index) => {
                    return <p key={index}>{value}</p>
                  })
}
            </div>
          </div>
        )
      })}</div>
  )
}

export const communityInvolvementInfo = (objectName = communityInvolvement) => {
  return (<div className="CommunityInvolvement">
        <h2>Community Involvement</h2>
        <br/>
        <ul>
        {objectName
          .map((point, i) => {
            return <div><li key={i}>{point.task}</li>
            {point.extra ? <ul> {point.extra.map((value, index) => {
              return <li key={index}>{value}</li> } ) }</ul>  : null }
              </div> } ) } </ul>
              </div>)
}

export const volunteerProjectsInfo = (objectName = volunteerProjects) => {
  return (<div className="VolunteerProjects">
    <h2>Volunteer Projects</h2>
        <br/>
        <ul>
        {objectName
          .map((point, i) => {
          return <div><li key={i}>{`${point.description} (${point.year})`}</li>
            </div>
          })}
      </ul>
  </div>)
}

export const otherTheartreInfo = (objectName = otherTheatreExperience) => {
  return (<div className="OtherTheatre">
    <h2>Other Theatre Related Experience</h2>
        <br/>
        <ul>
        {objectName
          .map((point, i) => {
          return <div><li key={i}>{`${point.description} (${point.year})`}</li>
            </div>
          })}
      </ul>
  </div>)
}

export const postgradLeaderInfo = (objectName = postgradLeader) => {
  return (<div className="PostgradLeader">
    <h2>Postgraduate Leadership</h2>
        <ul>
        {objectName
          .map((point, i) => {
          return <div><li key={i}>{`${point.details} (${point.years})`}</li>
            </div>
          })}
      </ul><br/>
  </div>)

        }

export const undergradLeaderInfo = (objectName = undergradLeader) => {
  return (<div className="UndergraduateLeadership">
        <h2>Undergraduate Leadership</h2>
        <ul>
        {objectName
          .map((point, i) => {
            return <div><li key={i}>{point.details}</li>
            {point.extras ? <ul> {point.extras.map((value, index) => {
              return <li key={index}>{value}</li> } ) }</ul>  : null }
              </div> } ) } </ul><br/>
              </div>)
}

export const undergradPlanningInfo = (objectName = undergradPlanning) => {
  return (<div className="UndergradPlanning">
    <h2>Undergraduate Planning</h2>
        <ul>
        {objectName
          .map((point, i) => {
          return <div><li key={i}>{`${point.details} (${point.years})`}</li>
            </div>
          })}
      </ul><br/>
  </div>)

        }
export const logoNav = (objectName = navLinks, color = guideColors, name = fullName) => {
  return (
    <div className="NavBar sticky-top">
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{
        backgroundColor: `${color.navBar}`
      }}>
        <a className="navbar-brand" href={`${objectName[0].hash}${objectName[0].link}`}>{name}</a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {objectName.map(item => {
              return <li className="nav-item" key={item.name}>
                <a className="nav-link" href={`${item.hash}${item.link}`}>{item.name}</a>
              </li>;
            })
}
          </ul>
        </div>
      </nav>
    </div>

  )
}

export const logolessNav = (objectName = navLinks, color = guideColors, name = fullName) => {
  return (
    <div className="NavBar sticky-top">
      <nav
        className="navbar navbar-expand-lg navbar-light"
        style={{
        backgroundColor: `${color.navBar}`
      }}>
        <a className="navbar-brand" href={`${objectName[0].hash}${objectName[0].link}`}> </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {objectName.map(item => {
              return <li className="nav-item" key={item.name}>
                <a className="nav-link" href={`${item.hash}${item.link}`}>{item.name}</a>
              </li>;
            })
}
          </ul>
        </div>
      </nav>
    </div>

  )
}