

export const colouring = {
    first: '#ffebf9',
    second: '#ebaad6',
    third: '#cc3baf',
};

export const guideColors = {
    navBar: colouring.second,
    footer: colouring.first,
    pageBackground: colouring.first,
    header: colouring.second,
};