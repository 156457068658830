import picture from '../../util/images/profileImage.jpg';
import { fullName } from "./personalInfo";

export const profilePicture = {
  alt: `${fullName}`,
  url: picture
};

export const profile = {
  name: "About Me",
  about: [`A current student studying arts management with a focus in audience participation, community, and accessibility. As a former small business owner, I am a creative self-starter with a passion for the arts and community involvement. My past experiences in talent management and theatre administration have given me a keen understanding of the theatre industry. Through my time in the retail sector, I have a strong eye for customer service and possess the ability to gain the trust of my clients and customers.`]
};

export const careerHighlights = {
  name: "Career Highlights",
  about: [`Worked in North Carolina, USA as part of the Educational
    Partners International LLC teacher exchange programme.`, `Delivered ICT training and support representing my
    company for external training.`, `Created and maintained my own digital platform for
    colleagues and students.`, `Created spreadsheet platforms for whole school data
    tracking.`]
};

export const competencies = {
  name: "Competencies",
  about: [
    `Created spreadsheet platforms for whole school data
    tracking.`,
    `Familiarity with JavaScript, Java, Ruby, C# and more
    including frameworks such as React, Vue, PhaserJS and
    more.`,
    `Experience working with Linux, MacOS, Windows, Bash and
    Git.`,
    `Extensive understanding of mathematics, capable of
    creating and understanding complex calculations,
    numerical data, statistics and using logic to form and solve
    problems.`,
    `Excellent communicator clear at expressing thoughts and
    an open listener.`,
    `Deadline management`
  ]
};

export const jumboInfo = {
  name: `${profile.name}`,
  subHead: ``,
};