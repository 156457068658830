import React, { Component } from 'react';
import { communityInvolvementInfo, jumbo, volunteerProjectsInfo, otherTheartreInfo } from "../JS/ElementFunctions";
import './Community.scss';


const pageTitle = {
    name: 'Work within the Community',
    subHead: '',
};


export class Community extends Component {
    render() {
        return (<div>
            {jumbo(pageTitle)}
            <div className="Community">    
                {communityInvolvementInfo()}
                {volunteerProjectsInfo()}
                {otherTheartreInfo()}
            </div></div>
        )
    }
}

