import React, { Component } from 'react';
import { undergradLeaderInfo, undergradPlanningInfo, postgradLeaderInfo, jumbo } from "../JS/ElementFunctions";
import './Leadership.scss';

const pageInfo = {
    name: 'Academic Leadership',
    subheading: '',
}

export class Leadership extends Component {
    render() {
        return (
            <div>
                {jumbo(pageInfo)}
            <div className="LeaderInfo">
                
                {postgradLeaderInfo()}
                {undergradLeaderInfo()}
                {undergradPlanningInfo()}
            </div></div>
        )
    }
}

