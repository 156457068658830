import React from 'react';
//import  { Link } from 'react-router-dom';
import {socialLinks} from '../../JS/pageLinks';
import { guideColors } from "../../JS/StyleObjects";
import './Footer.scss';
import { copyrightInfo } from '../../JS/personalInfo';

export class Footer extends React.Component {
  render() {
    return (
      <div className="Footer">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div
            style={{
            backgroundColor: `${guideColors.footer}`,
          }}
            className="NavBar fixed-bottom text-center">

            {socialLinks.map(item => {
              return <a
                href={item.link}
                className={`nav-brand ${item.objectClass} ${item.iconClass}`}> </a>;
            })}
            <span className="navbar-text">{copyrightInfo}</span>

          </div>
        </nav>
      </div>
    )
  }
}