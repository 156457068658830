import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect} from 'react-router-dom'; 
import '../../util/styles/AvailableFonts.css';
import { navLinks } from '../JS/pageLinks';

import { MainPage } from '../pages/index';

function App() {
  return (
            <Router>
            <Switch>
            {navLinks.map(item => {
                        return <Route exact path={item.link} component={item.pageName} />;
                    })}
            <Redirect to={MainPage} />
            </Switch>
            </Router>
  );
}

export default App;
