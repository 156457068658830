import React from 'react';
import { Footer } from '../Nav/Footer/Footer';
import { NavBar } from '../Nav/NavBar/NavBar';
import { Community } from '../Community/Community';


export const CommunityInv = () => {

    return (
        <div>
            <NavBar />
            <Community />
            <Footer />
        </div>

    );
}