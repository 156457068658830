export const jumboInfo = {
    name: 'Employment History',
    subHead: '',
};

export const employment = [
    /*{
        jobTitle: ['Full Time Sales Associate',],
        company: 'AT&T Certified Retail Store - Gastonia, NC, U.S.A.',
        startDate: 'April 2019',
        endDate: 'August 2019',
        duties: [
            'Strong focus on Customer Service, technical trouble shooting, and problem solving. Assisted customers in buying cell phones, phone plans, internet, and television services to fit their needs. Strategically evaluated existing customer plans and services to fit demands of changing technology while also keeping customers satisfied with costs and bill changes.',
        ],
    },*/{
        jobTitle: ['Founder and Talent Consultant',],
        company: 'The Courtney Studio - Cherryville, NC, U.S.A.',
        startDate: 'Feb 2017',
        endDate: 'Presesnt',
        duties: [
            'The Courtney Studio was a talent development company and acting studio providing one-on-one consulting sessions, self-tape audition services, and acting classes to hone the talents of the aspiring actor.',
        ],
    },{
        jobTitle: ['Talent Agent', 'Junior Talent Agent', 'Assistant to Owner, Nancy Curtis', 'Front Desk officer/Office Manger'],
        company: 'HCKR Agency (formerly Harden-Curtis Associates Talent Agency) - New York, NY, U.S.A.',
        startDate: 'Aug 2011',
        endDate: 'July 2016',
        duties: [
            'Franchised talent agent with Actor’s Equity Association. Negotiated contracts on behalf of acting clients in TV, Film, Independent Film, New York Theatre and Regional Theatre using Actor’s Equity and SAG/AFTRA union contracts as well as non-union rules. Managed talent submissions and materials including filming clients for self-taped auditions and image consulting. Very strong customer service skills, heavy clerical duty including filing, taking dictated letters and e-mails, and rolling calls on a heavy call system, creating and managing database systems, office management, etc. Extensive knowledge of InEntertainment database system, Castit Talent, and Breakdown Services as well as both Microsoft and Mac operating systems.',
        ],
    },{
        jobTitle: ['Full Time Family Room Specialist',],
        company: 'Apple Upper West Side Retail Store - New York, NY, U.S.A.',
        startDate: 'Oct 2009',
        endDate: 'July 2011',
        duties: [
            'Strong focus in Customer . Managed Genius Bar appointments, technical troubleshooting, and problem solving. Certified in the repair of Apple small iOS devices and trained to deliver personal training sessions. Demonstrated skill in multitasking in high pressure situations, diffusing customer service issues, and solution-oriented sensibilities. Has experience training fellow employees and managing retail floor.',
        ],
    },{
        jobTitle: ['2009-2010 Season Marketing Resident',],
        company: 'Playwrights Horizons Off-Broadway Theatre - New York, NY, U.S.A.',
        startDate: 'Aug 2009',
        endDate: 'May 2010',
        duties: [
            'Organized and coordinated grassroots efforts; served as liaison between Playwrights Horizons and Eliran Murphy Group advertising agency; designed and distributed mailings; served as liaison between Playwrights Horizons and subscriber database; Created student post cards and mailers; produced “On The Horizon” podcast series. ',
        ],
    },{
        jobTitle: ['Marketing Intern and Sales Associate',],
        company: 'Triad Stage Regional Theatre - Greensboro, NC, U.S.A.',
        startDate: 'Dec 2008',
        endDate: 'May 2009',
        duties: [
            'Performed demographic research for upcoming plays; coordinated press packets for cast and crew; prepared and implemented all online social networking updates and news blasts; sent out informational mail to student groups; distributed promotional posters and fliers.',
        ],
    },{
        jobTitle: ['Summer Marketing Intern',],
        company: 'Lark Play Development Center - New York, NY, U.S.A.',
        startDate: 'May 2008',
        endDate: 'August 2008',
        duties: [
            'Created Lark monthly newsletters, programs, and e-blasts using Microsoft Publisher and PatronMail. Created and updated Facebook and Myspace web pages. Served as ticket manager for readings.',
        ],
    },
];


export const communityInvolvement = [
    /*{
        task: [''],
        startDate: '',
        endDate: '',
        extra: [],
    },*/{
        task: ['Member of North Carolina Theatre Conference'],
        startDate: '',
        endDate: '',
        //extra: [],
    },{
        task: ['Cherryville Chamber of Commerce Board Member'],
        startDate: 2018,
        endDate: 'Dec 2018',
        extra: ['Promotions Chairman'],
    },{
        task: ['Member of the Junior League (New York City and Gaston County)'],
        startDate: 2013,
        endDate: 'Present',
        //extra: [],
    },{
        task: ['Member of North Carolina Theatre Conference'],
        startDate: '',
        endDate: '',
        extra: ['Executive Board Member', 'Membership Development Chairman', 'Community Enrichment Co-Chairman', 'Kids in the Kitchen Co-Chairman'],
    },{
        task: ['Graduate of GastonTogether’s Civic Engagement'],
        startDate: '',
        endDate: '',
        extra: ['Leadership 2nd Cohort'],
    },{
        task: ['Member of Carolina Film Community'],
        startDate: '',
        endDate: '',
        //extra: [],
    }
];

export const volunteerProjects = [
    /*{
        description: '',
        year: 2019,
    },*/{
        description: 'Coordinated a group of 10 members through the Junior League of Gaston County (JLGC) to “Adopt a Room” and redecorated the children’s playroom of the Cathy Cloninger Domestic Violence Shelter which sees between 3 to 12 infants and teens per day.',
        year: 2019,
    },{
        description: 'Assisted with the United Way of Gaston County’s “Christmas Wishes” program packing Christmas toys for underprivileged children. In my volunteer time, we completed 15 presents to go to underprivileged children and teens in Gaston County.',
        year: 2018,
    },{
        description: 'Volunteered as an Usher with the Charlotte Film Festival.',
        year: 2018,
    },{
        description: 'Revitalized the front yard and repainted the rooms of the Loray Girls Home, a group home housing 5-8 girls in foster care between the ages of 12-18 in Gastonia, NC.',
        year: 2018,
    },{
        description: 'Coordinated a children’s “Deconstructed Salad” event promoting healthy eating habits in partnership with the Gastonia Rotary Garden and the NC Cooperative Extension.',
        year: 2017,
    },{
        description: 'Participated in New York Junior League’s Playground Improvement Project to revitalize parks in Manhattan.',
        year: '2013 - 2016',
    },{
        description: 'Weekly volunteer with the New Jewish Home in Manhattan.',
        year: '2014 - 2016',
    },{
        description: 'Certified as a Rape Crisis Counselor with the state of New York as part of the DOVE program.',
        year: '2014 - 2016',
    },{
        description: 'Volunteered at the Broadway Cares Flea Market.',
        year: '2012, 2013, 2014, 2015',
    },
];

export const otherTheatreExperience = [
    /*{
        description: '',
        year: 2019,
    },*/{
        description: 'Created Lark monthly newsletters, programs, and e-blasts using Microsoft Publisher and PatronMail. Created and updated Facebook and Myspace web pages. Served as ticket manager for readings.',
        year: '',
    },{
        description: 'Taught classes on Breaking into the Entertainment Industry and How to “Make It” in New York City at various venues: One on One Productions, Actor’s Connection, The Actor’s Greenroom, Paul Michael’s THE NETWORK, The Actor’s Corner, etc.',
        year: '2012 - 2016',
    },{
        description: 'Concessionaire: Playwrights Horizons NY, NY.',
        year: '2009 - 2010',
    },{
        description: 'House Manager: KEF Productions, Theater Row NY, NY.',
        year: '2010',
    },{
        description: 'Member of Alpha Psi Omega Theatre Honors Fraternity.',
        year: '2006, 2007, 2008',
    },{
        description: 'Worked extensively in UNCG Theatre Scene Shop, Publicity Office, and Costume Shop.',
        year: '2006, 2007, 2008, 2009',
    },{
        description: 'UNCG Theatre House Manager.',
        year: '2008',
    },{
        description: 'Usher: UNCG Theatre and Triad Stage.',
        year: '2006, 2007, 2008, 2009',
    },{
        description: 'North Carolina Theatre for Young People Office secretary.',
        year: '2007',
    },
];