import React, {Component} from 'react'
import { jumboInfo } from "../JS/Profile";
import { jumbo, blurb, insertProfilePicture } from "../JS/ElementFunctions";
import './Profile.scss';

export class Profile extends Component {
  render() {
    return (
      <div className="Profile">
        {jumbo(jumboInfo)}
        
      <div className="Info">
        {insertProfilePicture()}
        {blurb()}
      </div>
      </div>
    )
  }
}
