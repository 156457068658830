
export const about = {
    firstName: 'Courtney',
    lastName: 'Beam',
    email: 'courtneybeam@gmail.com',
    website: 'https://courtneybeam.com',
    jobTitle: 'Arts Administrator',
    currentTitle: 'Arts Administrator',
    city: 'Barnsley',
    country: 'UK',
}

export const socialMediaLinks = {
        Twitter: "https://twitter.com/StudioCourtney",
        Pinterest: "https://www.pinterest.co.uk/thecourtney",
        LinkedIn: "https://www.linkedin.com/in/courtneybeam",
        Facebook: "https://www.facebook.com/thecourtneystudio",
        Instagram: "https://www.instagram.com/thecourtneystudio",
        YouTube: "https://www.youtube.com/channel/UCEobG_Dphgry62NYsB3pg8Q",
}

export const fullName = `${about.firstName} ${about.lastName}`;

export const subHeading = `${about.jobTitle}`;

export const location = `${about.city}, ${about.country}`;

export const jumboInfo = {
    name: fullName,
    subHead: subHeading,
};

const d = new Date();

export const copyrightInfo = `Copyright © ${d.getFullYear()} ${fullName}. All Rights Reserved.`;